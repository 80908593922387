'use strict';

(function($, window, document) {
  window.medimo = window.medimo || {};

  var kbNavOld;
  var popup;
  var keymapper = [];

  function MedimoModalActions() {}

  /**
   * no check if it's open, be smart about it.
   */
  MedimoModalActions.prototype.close = function() {
    window.removeEventListener('resize', window.auto_resize_formajaxprocessor_modal); // stop centering on every resize
    $(document).trigger("close.medimo-modal");
    $(document).off("keydown", this.keyMapper);

    var popup = $("#popupOverlay");
    popup.remove();
    window.medimo.KeyboardNavigation.enabled = kbNavOld;
    window.scrollFree();
  };

  /**
   * will execute bound functions to keycodes
   *
   * @param event
   */
  MedimoModalActions.prototype.keyMapper = function(event) {
    if (typeof keymapper[event.keyCode] !== "undefined") {
      for (var index in keymapper[event.keyCode]) {
        keymapper[event.keyCode][index].apply(window.medimo.ModalActions, [
          event
        ]);
      }
    }
  };

  MedimoModalActions.prototype.callbackModal = function(title, body, buttons) {
    scrollLock();
    var promise = $.Deferred();

    var obj = $("#popupOverlay");
    if (obj.length > 0) {
      obj.remove();
    }

    var $content = $("<div />", {
      class: "medimomodal-wrapper medimomodal-actions",
      id: "modal"
    }).append(
      $("<div />", { class: "medimomodal" })
        .append(
          $("<header />", { class: "medimomodal-header" })
            .text(title)
            .append('<div class="close" onclick="closePopup();">\n' +
        '                Sluiten' +
        '                <i class="medimo-icon icon-i-remove" style="font-size: 1.4em;"></i>\n' +
        '            </div>')
        )
        .append(
          $("<div />", { class: "medimomodal-ajax-container" }).append(
            $("<div />", { class: "medimomodal-content" }).html(body)
          )
        )
        .append($('<footer class="medimomodal-footer"></footer>'))
    );

    var $footer = $content.find("footer.medimomodal-footer");
    // for (var key in buttons) {
    for (var key = 0; key < buttons.length; key++) {
      var button = buttons[key];

      var newButton = $("<button />", button.attributes);
      if(button.attributes.tag === 'div') {
        newButton = $("<div />", button.attributes);
      }

      if (button.icon !== undefined) {
        newButton.append('<i class="fa fa-'+button.icon+'"></i> '+button.label);
      } else {
        newButton.text(button.label);
      }

      newButton.click(button.callback.bind(window.medimo.ModalActions));
      $footer.append(newButton);
      $footer.append(' '); // To force some padding between these inline-block elements

      if (typeof button.keycodes !== "undefined") {
        Object.keys(button.keycodes).forEach(function(t) {
          var keyCode = button.keycodes[t];
          if (typeof keymapper[keyCode] === "undefined") {
            keymapper[keyCode] = [];
          }
          keymapper[keyCode].push(button.callback);
        });
      }
    }

    popup = $('<div id="popupOverlay"/>').css("opacity", 0);
    popup.append($content);
    $("body").append(popup);

    window.auto_resize_formajaxprocessor_modal();
    window.addEventListener('resize', window.auto_resize_formajaxprocessor_modal); // center on every resize

    popup.css("opacity", 1);

    kbNavOld = window.medimo.KeyboardNavigation.enabled;
    window.medimo.KeyboardNavigation.enabled = false;

    var _this = this;
    popup.on("click", "a.close", function(event) {
      event.preventDefault();
      event.stopPropagation();

      _this.close();

      window.medimo.KeyboardNavigation.enabled = kbNavOld;
    });

    // bind the keymapper to the window on <keydown> -
    // because ESC can't bind bound to <keypress>
    $(document).on("keydown", this.keyMapper);

    promise.resolve(popup.get(0));
    return promise;
  };

  window.medimo.ModalActions = new MedimoModalActions();
})(window.$, window, document);
