'use strict';

if (typeof window === 'object') {
  window.Breadcrumbs = Breadcrumbs;
}

if (typeof module === 'object' && typeof module.exports !== 'undefined') {
  module.exports = Breadcrumbs;
}

function Breadcrumbs() {
  const self = this;

  this._searchField = '';
  this._currentSearchValue = '';
  this._currentUrl = '';
  this._currentDisplayStart = 0;
  this._showMaxItems = 3;
  this._toggleId = 'rememberToggle';
  this._sessionStorage = window.medimo.SessionStorage;

  // searchfield only exists after page loaded
  $(function() {
    self._searchField = $('[name="filtertext"]');

    self.bindKeyup();

    self.initiateMobileMenu();

    self.initiateRememberToggle(true);

    // bind paginator event
    $(document).on('page.dt', function (e, settings) {
      if (self._searchField.length === 0) {
        // Return if we have no _searchField to work with
        return;
      }

      // alleen pagina nummer opslaan indien ster aanstaat, of als zoekterm leeg is
      let items = window.medimo.RememberSearch.getValue();
      if ($.inArray(self._currentUrl, items) > -1 || self._searchField.val().length === 0) {

        let currentDisplayStart = 0;
        if (settings._iDisplayStart > 0) {
          currentDisplayStart = settings._iDisplayStart;
        }
        self.setDisplayStartValue(currentDisplayStart);
      }
    });
  });
}

Breadcrumbs.prototype.bindKeyup = function() {
  const self = this;

  self._searchField.keyup(function () {
    self.setDisplayStartValue(self._currentDisplayStart);
    self.setSearchValue(self._searchField.val());
  });
};

Breadcrumbs.prototype.reInitAfterAjaxLoadedDatatables = function() {
  const self = this;

  // searchfield only exists after page loaded
  self._searchField = $('[name="filtertext"]');

  // Make sure we only init when the star is not set yet
  // In tabs we can have multiple search fields (only one will be shown) injected by an ajax call
  // For now only the first search field will have a star because of "$('#'+this._toggleId).length === 0"
  // TODO: Make breadcrumbs work for multiple search fields on a page
  if (self._searchField.length > 0 && $('#'+this._toggleId).length === 0) {
    self.bindKeyup();
    self.initiateRememberToggle(false);
  }
};

Breadcrumbs.prototype.initiateMobileMenu = function() {
  $('#breadcrumb-mobile').click(function () {
    $('.menuset').hide();
    $(this).next().show().position({my: 'left top', at: 'left bottom', of: this});
    return false;
  }).buttonset().next().hide().menu();

  // hide menu when clicking elsewhere
  $('body').on('click touchstart', function (e) {
    if (!$(e.target).is('a')) {
      $('.menuset').hide();
    }
  });
};

Breadcrumbs.prototype.visitUrl = function(url) {
  // home & login: clear breadcrumbs
  if (url === '/' || url === '/login' || url === '/index.php') {
    this._sessionStorage.clearValue();
    return;
  }

  // set currentUrl
  this._currentUrl = url;

  // filter multiple status items, can happen with SSO
  //this.filterMultipleStatusCrumbs(url);

  // check for existing breadcrumb for this url
  this.cleanup(url);

  // empty searchValue if toggle isn't activated
  if (!this.toggleIsActivated(url)) {
    this._currentSearchValue = '';
  }

  // title
  let title = this.getTitle(url);

  this.add(url, title, this._currentSearchValue, this._currentDisplayStart);
};

Breadcrumbs.prototype.getTitle = function(url) {
  return '';

  // return last part of url
  // return url.substr(url.lastIndexOf('/') + 1).split('?')[0];
};

Breadcrumbs.prototype.add = function(url, title, searchValue, displayStart) {
  // empty if id
  if(!isNaN(title)) {
    title = '';
  }

  this._sessionStorage.addValue({
    url: url,
    title: title,
    search: searchValue,
    displayStart: displayStart
  });
};

Breadcrumbs.prototype.cleanup = function(url) {
  const self = this;
  const newItems = [];
  const items = this._sessionStorage.getValue();
  $(items).each(function() {
    // url already in crumbs || url is status url, if already a status url in crumbs, roll crumbs back to that point
    if(this.url === url || (this.url.includes('/status/') && url.includes('/status/'))) {
      self._sessionStorage.setValue(newItems);
      self._currentSearchValue = this.search;
      self._currentDisplayStart = this.displayStart;
      return this;
    }
    newItems.push(this);
  });
};

Breadcrumbs.prototype.setSearchValue = function(searchValue) {
  this._sessionStorage.setItemValueForKey('search', searchValue);
  this._currentSearchValue = searchValue;
};

Breadcrumbs.prototype.setDisplayStartValue = function(displayStart) {
  this._sessionStorage.setItemValueForKey('displayStart', displayStart);
};

Breadcrumbs.prototype.setTitle = function(title) {
  this._sessionStorage.setItemValueForKey('title', title);
};

Breadcrumbs.prototype.getCurrentSearchValue = function() {
  return this._currentSearchValue;
};

Breadcrumbs.prototype.getCurrentDisplayStartValue = function() {

  return this._currentDisplayStart;
};

Breadcrumbs.prototype.capitalizeFirstLetter = function(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

Breadcrumbs.prototype.draw = function() {
  const self = this;

  // clear items first
  $('ul.breadcrumbs li').not('li:first').remove();
  $('.breadcrumb-mobile .menuset li').not('li:first').remove();

  // add items to breadcrumb
  var items = this._sessionStorage.getValue();
  if (items === null) {
    return;
  }

  // set history back
  var length = items.length - 1;
  var previousTitle = '';
  $(items).each(function(index, value) {

    if(this.title === previousTitle) {
      this.title = '';
    }
    previousTitle = this.title;

    var backAmount = (length-index);
    if(backAmount > 0) {
      this.url = 'javascript:history.go(-' + (length - index) + ');';
    } else {
      this.url = '#';
    }
  });

  // filter empty title items
  items = $.grep(items, function( n, i ) {
    return (n.title.length > 0);
  });

  // start breadcrumb with '> ... >' if too many items
  if(items.length > this._showMaxItems) {
    $('ul.breadcrumbs').append($('<li>').append($('<a>').append('...')));
  }

  // max items to show on desktop
  var itemsDesktop = items.slice(Math.max(items.length - self._showMaxItems, 0));
  $(itemsDesktop).each(function () {

    // last item, no link!
    if(this.url === '#') {
      $('ul.breadcrumbs').append(
        $('<li>').append($('<span>').append(
          self.capitalizeFirstLetter(this.title)
        ))
      );
    }
    // all other items
    else {
      $('ul.breadcrumbs').append(
        $('<li>').append(
          $('<a>').attr('href', this.url).append(
            self.capitalizeFirstLetter(this.title)
          )
        )
      );
    }
  });

  // mobile breadcrumb
  $(items).each(function() {
    $('.breadcrumb-mobile .menuset').append(
      $('<li>').attr('class', 'ui-menu-item').append(
        $('<a>').attr('href', this.url).append(
          self.capitalizeFirstLetter(this.title)
        )
      )
    );
  });
};

/* star toggle */

Breadcrumbs.prototype.initiateRememberToggle = function(forceClear) {
  const self = this;

  // check searchfield is visible
  if(this._searchField.css("display") === "none") {
    return;
  }

  // toggle
  this._searchField.after('<div id="'+this._toggleId+'" class="fa fa-star-o" style="cursor:pointer; margin-left:-20px; margin-right:10px; position:relative;"></div>');
  $('#'+self._toggleId).click(function() {

    var items = window.medimo.RememberSearch.getValue();
    if(items === null) {
      items = [];
    }
    var activated = ($.inArray(self._currentUrl, items) > -1);

    // activate if not activated
    if (activated === false) {
      items.push(self._currentUrl);
    }
    // deactivate if activated
    else {
      var newItems = [];
      $(items).each(function(key, val) {
        if(val !== self._currentUrl) {
          newItems.push(val);
        }
      });
      items = newItems;
    }

    window.medimo.RememberSearch.setValue(items);

    self.setToggleState(true);
  });

  this.setToggleState(forceClear);
};

Breadcrumbs.prototype.setToggleState = function(forceClear) {
  var items = window.medimo.RememberSearch.getValue();

  // activate
  if ($.inArray(this._currentUrl, items) > -1) {
    $('#'+this._toggleId)
      .css("color", "#6ea5cc")
      .removeClass("fa-star-o")
      .addClass("fa-star");
  }
  // deactivate
  else {
    $('#'+this._toggleId)
      .css("color", "#aaa")
      .removeClass("fa-star")
      .addClass("fa-star-o");

    if(forceClear) {
      this._searchField.val('').keyup().trigger('input');
    }
  }
};

Breadcrumbs.prototype.toggleIsActivated = function(url) {
  const items = window.medimo.RememberSearch.getValue();

  return ($.inArray(url, items) > -1);
};
